import React from "react"
import { useEffect, useState } from "react"
import moment from "moment"
import ethImage from "../assets/images/eth-long.svg"
import // getNumPlayersFromCall,
// getIntervalFromCall,
// getLastTimeStampFromCall,
"../utils/2-matic-interact"

import { getMaticPriceInUSD } from "../utils/getMaticPrice"

const ZeroPointZeroOneEth = () => {
    const image = ethImage
    const network = "ERC20"
    const entryPrice = 0.01
    const token = "ETH"
    const comingSoon = true
    const contractUrl = "#"

    const [numPlayers, setNumPlayers] = useState(0)
    const [timeStamp, setTimeStamp] = useState(0)
    const [maticPriceInUSD, setMaticPriceInUSD] = useState(0)

    async function fetchNumPlayers() {
        const players = await getNumPlayersFromCall()
        setNumPlayers(players)
    }

    async function fetchMaticPrice() {
        const maticPrice = await getMaticPriceInUSD()
        setMaticPriceInUSD(maticPrice)
    }
    async function fetchInterval() {
        const int = await getIntervalFromCall()
        setInterval(int)
    }

    async function fetchTimeStamp() {
        const ts = await getLastTimeStampFromCall()
        setTimeStamp(ts)
    }

    useEffect(() => {
        fetchNumPlayers()
        fetchInterval()
        fetchTimeStamp()
        fetchMaticPrice()
    }, [])

    return (
        <div className="relative my-0">
            <img src={image} width={256} height={760} alt="img" />
            <div className="absolute bottom-4 left-8 mx-auto">
                <div
                    className="border-solid border-ttPrimaryLight rounded-lg h-fit bg-ttCatBg p-8 w-48 shadow-inner

"
                >
                    <h1 className="text-ttPrimary font-latoBold text-xs text-center mb">
                        Prize Money
                    </h1>
                    <h1 className="text-ttPrimary font-latoBold text-xl text-center">{`${(
                        entryPrice * numPlayers
                    ).toFixed(2)} ${token}`}</h1>
                    <h1 className="text-ttPrimary font-latoBold text-xs text-center mb-4">{`$(${(
                        entryPrice *
                        numPlayers *
                        maticPriceInUSD
                    ).toFixed(2)})`}</h1>

                    <p className="text-center text-ttGrey text-xs mb-4">
                        Winner Takes: <br />{" "}
                        {`${(entryPrice * numPlayers * 0.85).toFixed(2)} ${token} ($${(
                            entryPrice *
                            numPlayers *
                            0.85 *
                            maticPriceInUSD
                        ).toFixed(2)})`}{" "}
                    </p>
                    <p className="text-ttRed text-center text-xs font-latoBold">Entry Price</p>
                    <p className="text-ttRed text-center text-2xl font-latoBold">{`${entryPrice} ${token}`}</p>
                    <p className="text-ttGrey text-center text-xs font-latoBold mb-4">{`$(${(0).toFixed(
                        2
                    )}) USD`}</p>

                    <p className="text-ttPrimary text-center text-xs">Number of Players:</p>
                    <p className="text-ttPrimary text-center text-xl font-latoBold mb-4">{`${numPlayers}`}</p>

                    <p className="text-center text-ttWhite bg-ttPrimary pt-2 rounded-tl-xl rounded-tr-xl">
                        Network
                    </p>
                    <p className="text-center text-ttRed font-latoBold bg-white pb-2 rounded-bl-xl rounded-br-xl mb-4">
                        {network}
                    </p>

                    <div className="text-ttGrey text-center text-xs font-latoBold">
                        {/* Raffle Ends: <br />{" "}
                        {moment(Number(timeStamp * 1000 + 604800 * 1000)).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                        )} */}
                        Raffle Ends: <br /> {<p>Category Coming Soon</p>}
                        {<p>Watch Out for Updates</p>}
                    </div>
                </div>

                <div className="mt-8">
                    {comingSoon ? (
                        <button
                            className="text-sm text-ttPrimary bg-ttDisabled p-4 rounded-xl px-12 mx-auto  border-ttPrimary border-x border-y"
                            disabled
                        >
                            Coming Soon...
                        </button>
                    ) : (
                        <a href={contractUrl}>
                            <button className="animate-pulse text-ttWhite bg-ttPrimary p-4 rounded-xl px-16 hover:bg-ttPrimaryLight ">
                                Play Now
                            </button>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ZeroPointZeroOneEth
