import React from "react"
import ZeroPointOneBnb from "../../ZeroPointOneBnb"

const BNBCategories = () => {
    return (
        <section>
            {/* BNB Section */}
            <div className=" flex flex-col items-center mx-auto bg-ttLightBg">
                <div>
                    <h1 className="my-4 md:my-8  font-ubuntuBold text-2xl">BNB Categories</h1>
                </div>
                <div className="flex flex-col items-center lg:flex lg:flex-row lg:items-center lg:justify-between ">
                    <ZeroPointOneBnb />
                    <ZeroPointOneBnb />
                    <ZeroPointOneBnb />
                    <ZeroPointOneBnb />
                </div>
                <div className="my-4 md:my-8"></div>
            </div>
        </section>
    )
}

export default BNBCategories
