import React from "react"

const Terms = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h5 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        Terms of Use
                    </h5>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Tiditada.com is brought to you by Vanga DAO a Decentralised Autonomous
                        Organisation (collectively, “we,” “us,” or “our”) committed to creating free
                        transparent open-source software that users can interact with for financial
                        gains. Improvement proposals and modifications to the protocol are managed
                        in a community-driven way by holders of the VANGA DAO governance token.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        Tiditada.com provides information and resources about the fundamentals of
                        the decentralised, transparent, provably-fair, provable-random non-custodial
                        raffle protocol called the Tiditada Protocol (the “Tiditada Protocol” or
                        “Protocol”).
                    </p>
                    <p className="font-latoBold text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        BY ACCESSING OR USING OUR SERVICES, WHICH INCLUDE OUR VARIOUS WEBSITES,
                        INCLUDING, WITHOUT LIMITATION, TIDITADA.COM (AND ANY RESPECTIVE SUBDOMAINS);
                        APPLICATIONS (COLLECTIVELY WITH ANY MATERIALS AND SERVICES AVAILABLE
                        THEREIN, AND SUCCESSOR WEBSITE(S) OR APPLICATION(S) THERETO, THE “SITE”),
                        AND OTHER SERVICES THAT LINK TO THESE TERMS, AS WELL AS ANY INFORMATION,
                        TEXT, LINKS, GRAPHICS, PHOTOS, AUDIO, VIDEO, OR OTHER MATERIALS STORED,
                        RETRIEVED OR APPEARING THEREON, WHETHER ACCESSED THROUGH THE SITE OR
                        OTHERWISE (COLLECTIVELY, THE “SERVICES”), YOU ARE ENTERING INTO A BINDING
                        AGREEMENT WITH US THAT INCLUDES THESE TERMS
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        To the extent that there is a conflict between these Terms and any
                        applicable additional terms, these Terms will control unless expressly
                        stated otherwise. If you don't agree with these Terms, you may not use the
                        Services and should not visit the Site or otherwise engage with the
                        Services.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>Use of the Services</b> <br /> To use the Services, you must be legally
                        eligible according to the compliance requirements of your local
                        jurisdiction. You must be above 18 or 21 years of age, and must ensure that
                        using Tiditada services are legal in your jurisdiction, you represent and
                        warrant that you meet the eligibility requirement. If you do not meet the
                        requirement, you must not access or use the Site or the Services. We may
                        update the Services and the Terms. We may update the Services, the
                        Agreement, and any part of the Terms at any time. Improvement proposals are
                        first made in the community and members are asked to vote. Proposals that
                        are accepted by the majority of the community members are implemented. Once
                        any part of the Agreement is updated and in effect, you will be bound by the
                        Terms if you continue to use the Services, including by accessing the Site.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>2.Services</b> <br />{" "}
                        <b>Tiditada.com is provided as an informational resource.</b> <br /> To use
                        the Services, you must be legally Tiditada.com provides resources about the
                        fundamentals of the Tiditada Protocol, which is a fully decentralised,
                        community governed protocol deployed on multiple blockchain networks and
                        systems, and provides information about the wider Tiditada ecosystem,
                        governance, community, and various interfaces and integrations to the
                        Tiditada Protocol. All information provided in connection with your access
                        and use of the Site and the Services is for informational purposes only.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>We are software developers in the Tiditada Protocol ecosystem.</b> <br />{" "}
                        It is important to understand that neither we nor any of our infrastructure
                        entities is a party to any transaction on the blockchain networks underlying
                        the Tiditada Protocol; we do not have possession, custody or control over
                        any cryptoassets appearing on the Services; and we do not have possession,
                        custody, or control over any user’s funds. You understand that when you
                        interact with any Tiditada Protocol smart contracts, you retain control over
                        the private key associated with the wallet address from which you transfer
                        cryptoassets or the private key associated is the only private key that can
                        control the cryptoassets you transfer into the smart contracts. You alone
                        are responsible for securing your private keys. We do not have access to
                        your private keys. Due to the decentralised nature of the technology, we are
                        not intermediaries, agents or advisors, and we do not have a fiduciary
                        relationship or obligation to you regarding any other decisions or
                        activities that you affect when using our Services. You acknowledge that we,
                        for the avoidance of doubt, do not have any information regarding any users,
                        users’ identities, or services beyond what is available or obtainable
                        publicly via the blockchain. We are not responsible for any activities you
                        engage in when using Services, and you should understand the risks
                        associated with cryptoassets, blockchain technology generally, and our
                        Services. The Tiditada Protocol is deployed on multiple blockchain-based
                        networks, and we are not responsible for the operation of such networks.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        The software underlying blockchain networks on which the Tiditada Protocol
                        is deployed, including, for example, the Ethereum blockchain, is open
                        source, which means that anyone can use, utilize, and build on top of it. By
                        using the Services, you acknowledge and agree (i) that we are not
                        responsible for the operation of the blockchain-based software and networks
                        underlying the Tiditada Protocol, (ii) that there exists no guarantee of the
                        functionality, security, or availability of that software and networks, and
                        (iii) that the underlying blockchain-based networks are subject to sudden
                        changes in operating rules, such as those commonly referred to as “forks”.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>Transactions on the blockchain are not anonymous.</b> <br /> A widespread
                        belief is that transactions involving blockchains are anonymous. In fact, a
                        central feature of blockchains and thus, blockchain-based transactions, are
                        that they are transparent. Your public key and your wallet address, which
                        you need to buy or sell items on the blockchain, are visible to anyone. All
                        transactions using blockchains require the payment of gas fees, which are
                        essentially transaction fees paid on every transaction that occurs on the
                        selected blockchain network. Please note that gas fees are non-refundable.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>Tiditada Ecosystem and Community contributors are independent.</b> <br />
                        All community contributors to the ecosystem around the Tiditada Protocol are
                        independent of us, and we will not have and do not assume any liability or
                        responsibility for their actions or omissions.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>3.Assumption of Risk</b> <br />
                        You assume the risks of engaging in transactions that rely on smart
                        contracts and other experimental technology. Transactions on the Tiditada
                        Protocol rely on smart contracts stored on various blockchains,
                        cryptographic tokens generated by the smart contracts, and other nascent
                        software, applications and systems that interact with blockchain-based
                        networks. These technologies are experimental and subject to change. Among
                        other risks, bugs, malfunctions, cyberattacks, or changes to the applicable
                        blockchain (e.g., forks) could disrupt these technologies and even result in
                        a total loss of cryptoassets, their market value, or digital funds. You are
                        solely responsible for the safekeeping of the private key associated with
                        the blockchain address used to interact with the Protocol. We assume no
                        liability or responsibility for any such risks. If you are not comfortable
                        assuming these risks, you should not access or engage in transactions using
                        blockchain-based technology. One of the other defining features of
                        blockchain technology is that its entries are immutable, which means, as a
                        technical matter, they generally cannot be deleted or modified by anyone.
                        This includes smart contracts and cryptoassets generated and programmed by
                        smart contracts. THUS, TRANSACTIONS RECORDED ON THE BLOCKCHAIN, INCLUDING
                        TRANSFERS OF CRYPTOASSETS AND DATA PROGRAMMED INTO THESE ASSETS (SUCH AS
                        SERVER PAYMENT ALLOCATIONS), MUST BE TREATED AS PERMANENT AND CANNOT BE
                        UNDONE BY US OR BY ANYONE. We are not liable for any third-party services or
                        links. We are not responsible for the content or services of any
                        third-party, including, without limitation, any network, or apps like
                        Discord, or MetaMask, and we make no representations regarding the content
                        or accuracy of any third-party services or materials.
                        <br />
                        You agree to the automated collection and disbursement of proceeds by smart
                        contracts.You acknowledge and agree that all transactions accessed through
                        the Services will be automatically processed using one or more
                        blockchain-based smart contracts. By engaging in transactions using the
                        Services, you acknowledge and consent to the automatic processing of all
                        transactions in connection with using the Services. You further acknowledge
                        and agree that the applicable smart contract will dictate how the funds of a
                        transaction and ownership of cryptoassets are distributed. You acknowledge
                        the risks of using the Services.You bear sole responsibility for evaluating
                        the Services before using them, and all transactions accessed through the
                        Services are irreversible, final, and without refunds. The Services may be
                        disabled, disrupted or adversely impacted as a result of sophisticated
                        cyber-attacks, surges in activity, computer viruses, and/or other
                        operational or technical challenges, among other things. We disclaim any
                        ongoing obligation to notify you of all of the potential risks of using and
                        accessing our Services. You agree to (defined below) accept these risks and
                        agree that you will not seek to hold any of our Indemnified Parties
                        responsible for any consequent losses. <br /> You are solely responsible for
                        the security of your wallet.You understand and agree that you are solely
                        responsible for maintaining the security of your wallet. Any unauthorized
                        access to your wallet by third parties could result in the loss or theft of
                        any cryptoasset, or any funds held in your account and any associated
                        accounts. You understand and agree that we have no involvement in, and you
                        will not hold us responsible for managing and maintaining the security of
                        your wallet. You further understand and agree that we are not responsible,
                        and you will not hold us accountable, for any unauthorized access to your
                        wallet. It is your responsibility to monitor your wallet.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>We do not guarantee the quality or accessibility of the Services.</b>{" "}
                        <br />
                        As a condition to accessing or using the Services or the Site, you
                        acknowledge, understand, and agree that from time to time, the Site and the
                        Services may be inaccessible or inoperable for any reason, including, but
                        not limited to equipment malfunctions, periodic maintenance procedures or
                        repairs, causes beyond our control or that we could not reasonably foresee,
                        disruptions and temporary or permanent unavailability of underlying
                        blockchain infrastructure or unavailability of third-party service providers
                        or external partners for any reason. You acknowledge and agree that you will
                        access and use the Services, including, without limitation, the Site at your
                        own risk. You should not engage in blockchain-based transactions unless it
                        is suitable given your circumstances and financial resources. By using the
                        Services, you represent that you have been, are and will be solely
                        responsible for conducting your own due diligence into the risks of a
                        transaction and the underlying smart contracts and cryptoassets.
                    </p>
                    <p className="font-latoRegular text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        <b>4. Taxes</b> <br />
                        You are responsible for your taxes and duties. Users bear sole
                        responsibility for paying any and all taxes, duties, and assessments now or
                        hereafter claimed or imposed by any governmental authority associated with
                        their use of the Services, and/or payable as the result of using and/or
                        exploiting any cryptoassets and interacting with smart contracts.
                        Blockchain-based transactions are novel, and their tax treatment is
                        uncertain.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Terms
