import React, { useState, useEffect } from "react"
import Countdown from "react-countdown"

import { getLastTimeStampFromCall } from "../utils/05-matic-interact"

const Timer = () => {
    const [timeStamp, setTimeStamp] = useState("undefined")

    async function fetchTimeStamp() {
        const ts = await getLastTimeStampFromCall()
        setTimeStamp(ts)
    }

    const getTimer = (timeStamp) => {
        if (timeStamp < 604801) {
            const data = Number(timeStamp) * 1000 + 604800 * 1000
            return Number(data)
        }
    }

    const WinnerPicked = () => (
        <div className="md:absolute flex flex-col items-center justify-center md:ml-96">
            <p className="text-center border-1 border-ttPrimary bg-ttWhite text-ttPrimary rounded-tl-lg rounded-tr-lg font-latoRegular px-6 py-1">
                {" "}
                Winner has been picked!
            </p>
            <p className="text-center border-1 border-ttPrimary bg-ttPrimary text-ttWhite  rounded-bl-lg rounded-br-lg px-4 py-1 font-latoBold font-xl">
                {" "}
                Refresh to enter next raffle
            </p>
        </div>
    )

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (timeStamp < 604801) {
            return <span>{<p>Loading..</p>}</span>
        } else if (completed) {
            // Render a completed state
            return <WinnerPicked />
        } else {
            return (
                <div className="md:absolute flex flex-col items-center justify-center md:ml-80">
                    <div className="bg-ttRed text-ttWhite rounded-tl-lg rounded-tr-lg px-8 py-2">
                        <h3>Raffle Ends</h3>
                    </div>
                    <div className="flex flex-row items center justify-center">
                        <div>
                            <p className="text-center border-1 border-ttPrimary bg-ttWhite text-ttPrimary rounded-tl-lg  font-latoRegular px-4 py-1">
                                Day(s)
                            </p>
                            <p className="text-center border-1 border-ttPrimary bg-ttPrimary text-ttWhite  rounded-bl-lg px-4 py-1 font-latoBold font-xl">
                                {days}
                            </p>
                        </div>
                        <div>
                            <p className="text-center border-1 border-ttPrimary bg-ttWhite text-ttPrimary font-latoRegular px-4 py-1">
                                Hour(s)
                            </p>
                            <p className="text-center border-1 border-ttPrimary bg-ttPrimary text-ttWhite px-4 py-1 font-latoBold font-xl">
                                {hours}
                            </p>
                        </div>
                        <div>
                            <p className="text-center border-1 border-ttPrimary bg-ttWhite text-ttPrimary font-latoRegular px-4 py-1">
                                Minute(s)
                            </p>
                            <p className="text-center border-1 border-ttPrimary bg-ttPrimary text-ttWhite px-4 py-1 font-latoBold font-xl">
                                {minutes}
                            </p>
                        </div>
                        <div>
                            <p className="text-center border-1 border-ttPrimary bg-ttWhite text-ttPrimary rounded-tr-lg font-latoRegular px-4 py-1">
                                Second(s)
                            </p>
                            <p className="text-center border-1 border-ttPrimary bg-ttPrimary text-ttWhite rounded-br-lg  px-4 py-1 font-latoBold font-xl ">
                                {seconds}
                            </p>
                        </div>
                    </div>
                </div>
            )
        }
    }

    useEffect(() => {
        fetchTimeStamp()
    }, [])

    return (
        <div className="container mx-auto">
            {timeStamp !== "undefined" && (
                <Countdown date={Number(timeStamp) * 1000 + 604800 * 1000} renderer={renderer} />
            )}
        </div>
    )
}

export default Timer
