import React from "react"
import { Link } from "react-router-dom"
import logo from "../assets/images/white-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
    faTwitter,
    faInstagram,
    faFacebook,
    faTelegram,
    faTiktok,
} from "@fortawesome/free-brands-svg-icons"
const Footer = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className=" bg-ttPrimary">
                <div className="container mx-auto pt-8  flex flex-col items-center md:flex md:flex-row md:items-start md:justify-between">
                    <div className="flex flex-col items-center space-y-4 md:flex md:flex-row md:items-start md:justify-between md:space-x-16">
                        <div>
                            <div>
                                <img src={logo} height={40} width={120} alt="img" />
                            </div>
                            <div className="flex flex-col items-center md:items-start md:mt-4">
                                <div className="py-2">
                                    <Link to="/terms">
                                        <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                            Terms of Use
                                        </a>
                                    </Link>
                                </div>
                                <div className="py-2">
                                    <Link to="/disclaimer">
                                        <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                            Disclaimer
                                        </a>
                                    </Link>
                                </div>
                                <div className="py-2">
                                    <a
                                        href="https://www.tiditada.blog"
                                        className="text-ttWhite font-latoRegular hover:text-ttHero"
                                    >
                                        Blog
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col items-center md:block ">
                            <div className="pt-10">
                                <p className="text-ttWhite font-latoRegular">POLYGON (MATIC)</p>
                            </div>
                            <div className="pt-4">
                                <Link to="/how-to-play">
                                    <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                        How to Play
                                    </a>
                                </Link>
                            </div>
                            <div className="pt-4">
                                <Link to="/play-with-metamask">
                                    <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                        Play via MetaMask
                                    </a>
                                </Link>
                            </div>
                            <div className="py-2">
                                <Link to="/play-with-binance">
                                    <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                        Play via Binance
                                    </a>
                                </Link>
                            </div>
                            <div className="py-2">
                                <Link to="/add-polygon-to-metamask">
                                    <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                        Add Polygon to MetaMask
                                    </a>
                                </Link>
                            </div>
                            <div className="py-2">
                                <Link to="/binance-to-metamask">
                                    <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                        Fund MetaMask via Binance
                                    </a>
                                </Link>
                            </div>
                            <div className="py-2">
                                <Link to="/conditions">
                                    <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                        Raffle Conditions
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <div className="flex flex-col items-center md:block ">
                            <div className="pt-10">
                                <Link to="/developers">
                                    <a className="text-ttWhite font-latoRegular hover:text-ttHero">
                                        Developers
                                    </a>
                                </Link>
                            </div>
                            <div className="py-2">
                                <a
                                    href="https://github.com/vangaLabs/tiditada.git"
                                    className="text-ttWhite font-latoRegular hover:text-ttHero"
                                >
                                    GitHub
                                </a>
                            </div>
                            <div className="py-2">
                                <a
                                    href="https://snapshot.org/#/vangadao.eth/"
                                    className="text-ttWhite font-latoRegular hover:text-ttHero"
                                >
                                    Governance
                                </a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className=" space-y-4">
                            <div className="flex flex-col items-center">
                                <p a className="text-ttWhite font-latoRegular md:mt-0 mt-8">
                                    CONNECT WITH US
                                </p>
                            </div>

                            <div className="flex flex-row space-x-4">
                                <a href="https://www.twitter.com/tidi_tada">
                                    <FontAwesomeIcon
                                        icon={faTwitter}
                                        style={{ fontSize: 28, color: "white" }}
                                        className="hover: cursor-pointer"
                                    />
                                </a>

                                <a href="https://www.instagram/officialtiditada">
                                    <FontAwesomeIcon
                                        icon={faInstagram}
                                        style={{ fontSize: 28, color: "white" }}
                                        className="hover: cursor-pointer"
                                    />
                                </a>

                                <a href="https://www.facebook.com/Officialtiditada">
                                    <FontAwesomeIcon
                                        icon={faFacebook}
                                        style={{ fontSize: 28, color: "white" }}
                                        className="hover: cursor-pointer"
                                    />
                                </a>

                                <a href="https://www.tiktok.com/@officialtiditada">
                                    <FontAwesomeIcon
                                        icon={faTiktok}
                                        style={{ fontSize: 28, color: "white" }}
                                        className="hover: cursor-pointer"
                                    />
                                </a>

                                <a href="https://t.me/officialtiditada">
                                    <FontAwesomeIcon
                                        icon={faTelegram}
                                        style={{ fontSize: 28, color: "white" }}
                                        className="hover: cursor-pointer"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <p className="text-center font-latoRegular text-xs text-ttWhite pb-2 pt-36">
                    VangaDAO &copy; 2022
                </p>
            </div>
        </section>
    )
}

export default Footer
