import React from "react"

import binanceWithdraw from "../assets/images/binanceWithdraw.svg"
import enterAmount from "../assets/images/enterAmount.svg"
import withdrawSteps from "../assets/images/withdrawSteps.svg"
import copyAddress from "../assets/images/copyAddress.svg"

const PlayBinance = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h6 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        How to Play via Binance
                    </h6>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Read to learn exactly how play Tiditada Raffle from Binance or other
                        centralized exchanges like Coinbase, KuCoin, Gateio, e.t.c
                    </p>

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        <p className="font-latoBold">How to Play</p>
                        Before you can get started playing via Binance, you will first need to set
                        up a Binance Account (if you do not have one already). You can learn how to
                        create one{" "}
                        <a
                            href="https://www.binance.com/en/support/faq/how-to-register-on-binance-app-360042718372"
                            className="text-ttHero"
                        >
                            here
                        </a>{" "}
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Now it is time to get the Raffle Address that you want to enter. Navigate to
                        the Raffle Page and click on "Copy Address" to copy the Raffle Address
                    </p>

                    <img
                        src={copyAddress}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={copyAddress}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Next, go to Binance.com, log into your account, and proceed to complete your
                        Raffle entrance by transferring the Raffle Entrance Fee to the Raffle
                        Address you have just copied, you can also access your Binance account using
                        the Binance mobile app on your phone. To complete the transfer, do the
                        following;
                    </p>

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        1. Head over to your Binance account dashboard and click on [withdraw].
                    </p>
                    <img
                        src={binanceWithdraw}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={binanceWithdraw}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        2. Select MATIC under [Coin] dropdown
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        3. Paste the Raffle address copied from Raffle Page under the [Address]
                        section.
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        4. Next, choose the Matic in the [Network] drop-down
                    </p>
                    <img
                        src={withdrawSteps}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={withdrawSteps}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        5. Enter Raffle Entrance Fee under [Amount] section and click on withdraw
                    </p>
                    <img
                        src={enterAmount}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={enterAmount}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        And that’s it! That’s how easy it is to play Tiditada from Binance or other
                        Centralized Exchanges.
                        <br />
                        Goodluck!
                    </p>
                </div>
            </div>
        </section>
    )
}

export default PlayBinance
