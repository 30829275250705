import React from "react"
import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Developers = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="flex flex-col items-right flex-col-reverse  container mx-auto mt-2 mb-4 md:px-12 md:mt-12 md:mb-12 md:flex md:flex-col md:flex-col-reverse md:items-end md:space-x-2">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl mx-4 p-4 md:mx-0
                    

"
                >
                    <h3 className="text-ttPrimary font-latoBold text-2xl px-2 mt-4">
                        What it TidiTada?
                    </h3>
                    <h4 className="text-ttPrimary font-latoBold text-xl py-4 px-2">
                        Protocol, Interface, VangaDAO
                    </h4>
                    <p className="text-justify text-ttPrimary font-latoRegular p-2">
                        To begin, we should make clear the distinctions between the different areas
                        of "Tiditada", some of which may be incomprehensible to newbies.
                    </p>
                    <p className="text-justify text-ttPrimary font-latoRegular p-2">
                        <b>VangaDAO: </b>The organisation which developed the Tiditada protocol,
                        along with the web interface. VangaDAO is a Decentralised Autonomous
                        Organisation committed to creating free transparent open-source software.
                        Improvement proposals and modifications to the protocol are managed in a
                        community-driven way by holders of the VANGA DAO governance token.
                    </p>
                    <p className="text-justify text-ttPrimary font-latoRegular p-2">
                        <b>The Tiditada Protocol: </b>A persistent, non-upgradable, automated smart
                        contract that implements a transparent, provably-fair, provably-verifiable,
                        decentralised raffle. It operates with native blockchain tokens and is
                        available on multiple chains. The automation and randomness is implemented
                        by different Chainlink contracts.
                    </p>
                    <p className="text-justify text-ttPrimary font-latoRegular p-2">
                        <b>The Tiditada Interface: </b>A web user interface built on Nextjs that
                        allows for easy interaction with the Tiditada protocol. The interface is
                        only one of many ways one may interact with the Tiditada protocol. Users can
                        also interact with contracts directly by writing or reading to contract
                        addresses using contract-specific blockchain explorers, e.g Etherscan.
                    </p>
                    <p className="text-justify text-ttPrimary font-latoRegular p-2">
                        <b>Tiditada Governance: </b> A governance system for governing the Tiditada
                        Protocol, enabled by the VANGADAO token.
                    </p>
                </div>
                <div
                    className="flex flex-row mx-4 mb-4 items-center border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl  md:px-2 md:mr-4 md:mx-0

"
                >
                    <div>
                        <FontAwesomeIcon
                            icon={faGithub}
                            style={{ fontSize: 36, color: "hsl(199, 100%, 6%)" }}
                            className="pl-4 py-4 hover: cursor-pointer"
                        />
                    </div>{" "}
                    <div>
                        <a
                            className="p-6 text-ttPrimary font-latoBold text-xl"
                            href="https://github.com/vangaLabs/tiditada.git"
                        >
                            Github &#8599;
                        </a>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Developers
