import { createAlchemyWeb3 } from "@alch/alchemy-web3"
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY
const web3 = createAlchemyWeb3(alchemyKey)

import contractABI from "../constants/ABI.json"
const abi = contractABI
const contractAddress = "0xCCC5a488A0173c199AfE312916809aa0DD6A48aF"

export const tidiTada = new web3.eth.Contract(abi, contractAddress)

export const getNumPlayersFromCall = async () => {
    const numPlayers = await tidiTada.methods.getNumberOfPlayers().call()
    return numPlayers
}

export const getIntervalFromCall = async () => {
    const interval = await tidiTada.methods.getInterval().call()
    return interval
}
export const getLastTimeStampFromCall = async () => {
    const lastTimeStamp = await tidiTada.methods.getLastTimeStamp().call()
    return lastTimeStamp
}
export const getTidiTadaFeeFromCall = async () => {
    const tidiTadaFee = await tidiTada.methods.getTidiTadaFee().call()
    return tidiTadaFee
}
export const getTidiTadaWinnerFromCall = async () => {
    const tidiTadaWinner = await tidiTada.methods.getTidiTadaWinner().call()
    return tidiTadaWinner
}
export const getJackpotFromCall = async () => {
    const jackpot = await tidiTada.methods.getJackpot().call()
    return jackpot
}
export const getLatestNumberOfPlayersFromCall = async () => {
    const latestNumPlayers = await tidiTada.methods.getLatestNumberOfPlayers().call()
    return latestNumPlayers
}
