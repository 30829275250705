import React from "react"
import { Link } from "react-router-dom"
import globe from "../assets/images/globe.svg"

const Hero = () => {
    return (
        <section>
            {/* Hero Section */}
            <div className="container flex flex-col items-center  mx-auto mt-4 space-y-0  md:space-y-0 md:flex md:flex-row md:items-center md:justify-between md:my-auto space-x-12">
                {/* Message */}
                {/* Desktop */}
                <div className="hidden md:flex md:flex-col md:mb-12 md:space-y-4 md:w-1/2 md:my-auto">
                    <h1 className="max-w-md text-4xl font-ubuntuBold text-center text-ttGrey md:text-5xl md:text-left">
                        The World's First{" "}
                    </h1>

                    <div>
                        <h2>
                            <span> </span>
                            <div className="message">
                                <div className="word1">Decentralized</div>
                                <div className="word2">Transparent</div>
                                <div className="word3">Verifiable</div>
                            </div>
                            <span></span>
                        </h2>
                    </div>
                    <h1 className="max-w-md text-4xl font-ubuntuBold text-center text-ttRed md:text-5xl md:text-left">
                        Crypto Raffle
                    </h1>

                    <p className="max-w-sm font-latoRegular text-center ml-6 text-ttGrey md:text-left md:ml-0">
                        100% Decentralized <br />
                        Fully Transparent <br />
                        Play Anonymously
                        <br />
                        Play on Multiple Chains
                    </p>
                    {/* <div className="flex flex-col items-center justify-center md:flex md:flex-row md:items-start md:justify-start">
                        <Link to="/matic">
                            <a className="p-3 px-6 pt-2 text-ttWhite bg-ttPrimary cursor-pointer rounded-full baseline align-middle hover:bg-ttPrimaryLight">
                                Play With MATIC
                            </a>
                        </Link>
                    </div> */}
                </div>

                {/* Mobile */}
                <div className="md:hidden flex flex-col items-center  pr-16 mb-12 space-y-4  md:my-auto">
                    <div>
                        <h1 className="max-w-md text-2xl font-ubuntuBold text-center text-ttPrimary md:text-5xl md:text-left">
                            The World's First{" "}
                            <span className="text-ttHero">
                                {" "}
                                Decentralized <br />
                                Transparent
                                <br /> Verifiable
                                <br />{" "}
                            </span>
                            Crypto Raffle
                        </h1>
                    </div>

                    {/* <div>
                        <h2>
                            <span> </span>
                            <div className="message">
                                <div className="word1">Decentralized</div>
                                <div className="word2">Transparent</div>
                                <div className="word3">Verifiable</div>
                            </div>
                            <span></span>
                        </h2>
                    </div> */}
                    <div>
                        <p className="max-w-sm font-latoRegular text-center ml-6 text-ttGrey md:text-left md:ml-0">
                            100% Decentralized <br />
                            Fully Transparent <br />
                            Play Anonymously
                            <br />
                            Play on Multiple Chains
                        </p>
                    </div>
                </div>

                {/* Image */}
                <div className="hidden md:block">
                    <img src={globe} width={640} height={640} alt="img" />
                </div>
            </div>
        </section>
    )
}

export default Hero
