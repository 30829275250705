import React from "react"
import PointFiveMaticCategory from "../../PointFiveMaticCategory"
import TwoMatic from "../../TwoMatic"

const MaticCategories = () => {
    return (
        <section>
            {/* Matic Section */}
            <div className=" flex flex-col items-center mx-auto bg-ttLightBg">
                <div>
                    <h1 className="my-4 md:my-8 font-ubuntuBold text-2xl">MATIC Categories</h1>
                </div>
                <div className="flex flex-col items-center lg:flex lg:flex-row lg:items-center lg:justify-between ">
                    <PointFiveMaticCategory />
                    <TwoMatic />
                </div>
                <div className="my-4 md:my-8"></div>
            </div>
        </section>
    )
}

export default MaticCategories
