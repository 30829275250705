import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import Header from "./components/Header"
import Footer from "./components/Footer"

import Home from "./pages/Home"
import ErrorPage from "./pages/ErrorPage"
import BNB from "./pages/BNB"
import Developers from "./pages/Developers"
import Disclaimer from "./pages/Disclaimer"
import Matic from "./pages/Matic"
import Terms from "./pages/Terms"
import AddPolygonToMetamask from "./pages/AddPolygonToMetamask"
import BinanceToMetamask from "./pages/transferBinanceToMetamask"
import PlayMetamask from "./pages/PlayMetamask"
import PlayBinance from "./pages/PlayBinance"
import RaffleConditions from "./pages/RaffleConditions"
import HowToPlay from "./pages/HowToPlay"
// import logo from "./logo.svg"
// import "./App.css"
import "./styles/globals.css"

const App = () => {
    return (
        <Router>
            <Header />
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/bnb" component={BNB} exact />
                <Route path="/developers" component={Developers} exact />
                <Route path="/disclaimer" component={Disclaimer} exact />
                <Route path="/matic" component={Matic} exact />
                <Route path="/terms" component={Terms} exact />
                <Route path="/add-polygon-to-metamask" component={AddPolygonToMetamask} exact />
                <Route path="/binance-to-metamask" component={BinanceToMetamask} exact />
                <Route path="/play-with-metamask" component={PlayMetamask} exact />
                <Route path="/play-with-binance" component={PlayBinance} exact />
                <Route path="/conditions" component={RaffleConditions} exact />
                <Route path="/how-to-play" component={HowToPlay} exact />

                <Route component={ErrorPage} />
            </Switch>
            <Footer />
        </Router>
    )
}
export default App

// function App() {
//     return (
//         <div className="App">
//             <header className="App-header">
//                 <img src={logo} className="App-logo" alt="logo" />
//                 <p>
//                     Edit <code>src/App.js</code> and save to reload.
//                 </p>
//                 <a
//                     className="App-link"
//                     href="https://reactjs.org"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                 >
//                     Learn React
//                 </a>
//             </header>
//             <Header />
//             <Footer />
//         </div>
//     )
// }
