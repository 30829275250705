import React from "react"
import { utils } from "ethers"
import { useEffect, useState } from "react"
import moment from "moment"
import {
    getLastTimeStampFromCall,
    getTidiTadaFeeFromCall,
    getTidiTadaWinnerFromCall,
    getJackpotFromCall,
    getLatestNumberOfPlayersFromCall,
} from "../../utils/2-matic-interact"

import { getMaticPriceInUSD } from "../../utils/getMaticPrice"

const TwoMaticWinner = () => {
    const [timeStamp, setTimeStamp] = useState(0)
    const [fee, setFee] = useState(0)
    const [winner, setWinner] = useState("")
    const [jackpot, setJackpot] = useState(0)
    const [latestNumPlayers, setLatestNumPlayers] = useState(0)

    const [maticPriceInUSD, setMaticPriceInUSD] = useState(0)

    const [dateValue, setDateValue] = useState(0)
    const [ethBal, setEthBal] = useState(0)
    const [address, setAddress] = useState("")
    const [amountWon, setAmountWon] = useState("")
    const [players, setPlayers] = useState("")

    useEffect(() => {
        const ndate = moment(timeStamp * 1000).format("DD  MMM   YYYY")
        setDateValue(ndate)
    }, [timeStamp])

    useEffect(() => {
        const nethBal = utils.formatEther(fee)
        setEthBal(nethBal)
    }, [fee])

    useEffect(() => {
        const winnerDetails = winner
        setAddress(winnerDetails)
    }, [winner])

    async function fetchTimeStamp() {
        const timeStp = await getLastTimeStampFromCall()
        setTimeStamp(timeStp)
    }

    async function fetchFee() {
        const entryPrice = await getTidiTadaFeeFromCall()
        setFee(entryPrice)
    }

    async function fetchWinner() {
        const winnerAcc = await getTidiTadaWinnerFromCall()
        setWinner(winnerAcc)
    }

    async function fetchJackpot() {
        const jackpotPrice = await getJackpotFromCall()
        setAmountWon(Number(utils.formatEther(jackpotPrice)).toFixed(3))
    }

    async function fetchNumPlayers() {
        const numPlayers = await getLatestNumberOfPlayersFromCall()
        setPlayers(numPlayers)
    }
    ;<div>{amountWon}</div>

    // async function fetchMaticPrice() {
    //     const maticPrice = await getMaticPriceInUSD()
    //     setMaticPriceInUSD(maticPrice)
    // }

    useEffect(() => {
        fetchTimeStamp()
        fetchFee()
        fetchWinner()
        fetchJackpot()
        fetchNumPlayers()
    }, [])

    return (
        <div>
            <section>
                {/* MATIC Winner Section */}
                {/*  Mobile */}
                <div>
                    {timeStamp < 604801 ? (
                        <div className="flex flex-col items-center md:hidden">
                            <div className="skeleton skeleton-mobile-date skeleton-loading"></div>
                            <div className="skeleton skeleton-mobile-address skeleton-loading"></div>
                            <div className="skeleton skeleton-mobile-jackpot skeleton-loading"></div>
                            <div className="skeleton skeleton-mobile-entry skeleton-loading"></div>
                            <div className="skeleton skeleton-mobile-players skeleton-loading"></div>
                            <div className="skeleton skeleton-mobile-verify skeleton-loading"></div>
                        </div>
                    ) : (
                        <div className="container flex flex-col items-center mx-auto mb-8 h-fit md:hidden ">
                            <div className="text-ttGrey text-center">{`Date: ${dateValue}`}</div>
                            <div className="text-ttGrey text-center w-auto">{`Wallet Address: ${address}`}</div>
                            <div className="text-ttGrey text-center w-auto">{`Jackpot: ${amountWon}`}</div>
                            <div className=" text-center text-ttGrey">{`Entry Price: ${ethBal} MATIC`}</div>
                            <div className=" text-center text-ttGrey">{`Number of Players: ${players}`}</div>
                            <div className="  text-center text-ttGrey mt-2">
                                {
                                    <a
                                        href={`https://polygonscan.com/address/${address}#internaltx`}
                                    >
                                        <button className="bg-ttPrimary text-ttWhite rounded-xl py-2 px-4 hover:bg-ttPrimaryLight">
                                            VERIFY
                                        </button>
                                    </a>
                                }
                            </div>
                        </div>
                    )}

                    {/* Desktop */}
                    {timeStamp < 604801 ? (
                        <div className="flex flex-row items-center">
                            <div className="skeleton skeleton-date skeleton-loading"></div>
                            <div className="skeleton skeleton-address skeleton-loading"></div>
                            <div className="skeleton skeleton-jackpot skeleton-loading"></div>
                            <div className="skeleton skeleton-entry skeleton-loading"></div>
                            <div className="skeleton skeleton-players skeleton-loading"></div>
                            <div className="skeleton skeleton-verify skeleton-loading"></div>
                        </div>
                    ) : (
                        <div className="hidden container mx-auto   h-fit md:block ">
                            <table className="table-auto md:mx-auto">
                                <thead className="pb-8">
                                    <tr>
                                        <th className=""> </th>
                                        <th className="md:mx-auto"> </th>
                                        <th className="md:pl-12 md:mx-auto md:text-center"> </th>
                                        <th className="md:pl-12 md:mx-auto md:text-center"> </th>
                                        <th className="md:pl-12 md:mx-auto md:text-center"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="pr-12 text-ttGrey">
                                            <div>{dateValue}</div>
                                        </td>
                                        <td className="text-ttGrey pr-8">
                                            <div>{address}</div>
                                        </td>
                                        <td className="mx-auto text-ttGrey pr-16">
                                            <div>{amountWon}</div>
                                            {/* <div>{utils.formatEther(amountWon)}</div> */}
                                        </td>
                                        <td className="mx-auto text-center text-ttGrey pr-24">
                                            <div>{ethBal}</div>
                                        </td>
                                        <td className=" mx-auto text-center text-ttGrey pr-12">
                                            <div>{players}</div>
                                        </td>
                                        <td className=" pl-16 mx-aut text-center text-ttGrey">
                                            <div>
                                                {
                                                    <a
                                                        href={`https://polygonscan.com/address/${address}#internaltx`}
                                                    >
                                                        <button className="bg-ttPrimary text-ttWhite rounded-xl py-2 px-4 hover:bg-ttPrimaryLight">
                                                            VERIFY
                                                        </button>
                                                    </a>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </section>
        </div>
    )
}
export default TwoMaticWinner
