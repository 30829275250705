import React from "react"
import TwoMaticWinner from "./winners/TwoMaticWinner"
import PointFiveMaticWinner from "./winners/PointFiveMaticWinner"

const RecentWinnersList = () => {
    return (
        <section>
            {/* Recent Winners Section */}
            <div className=" flex flex-col items-center mx-auto h-fit container">
                <div>
                    <h1 className="mt-8 font-ubuntuBold text-2xl md:mb-2">
                        Recent Winners (MATIC)
                    </h1>
                </div>
                <div className=" mx-auto  mb-4 mt-8 h-fit  shadow-inner">
                    <table className="hidden table-auto md:mx-auto md:block">
                        <thead className="mx-auto">
                            <tr>
                                <th className="md:pt-4 md:pr-56">Date </th>
                                <th className="md:pt-4 md:mx-auto pr-12">Address</th>
                                <th className="md:pt-4 md:mx-auto pl-44">Jackpot</th>
                                <th className="md:pt-4 md:pl-8 md:mx-auto md:text-center ">
                                    Entry Price
                                </th>
                                <th className="md:pt-4 md:pl-8 md:mx-auto md:text-center">
                                    Number of Players
                                </th>
                                <th className="md:pt-4 md:pl-16 md:mx-auto md:text-center">
                                    Verify On-Chain
                                </th>
                            </tr>
                        </thead>{" "}
                    </table>
                </div>
                <div>
                    <PointFiveMaticWinner />
                    <TwoMaticWinner />
                </div>
            </div>
        </section>
    )
}

export default RecentWinnersList
