import React from "react"
import { Link } from "react-router-dom"
import YoutubeEmbed from "./YoutubeEmbed"

const HowToPlayVideos = () => {
    return (
        <section>
            {/* How to Play Section */}
            <div className="bg-ttPrimary flex flex-col items-center mx-auto h-fit mt-4 md:mt-12">
                <div>
                    <h1 className="mt-8 font-ubuntuBold text-2xl text-ttWhite ">How To Play</h1>
                </div>
                <div className=" flex flex-col items-center  mx-auto py-2 mb-4 h-fit md:w-auto md:flex md:flex-row md:items-center md:justify-between md:space-x-12">
                    <div>
                        <p className="font-latoLight text-ttWhite p-2 pb-8 md:p-8 text-justify">
                            How to Play with Trust Wallet
                        </p>
                        <YoutubeEmbed embedId="DeBI2DgqDW0" />
                    </div>
                    <div>
                        <p className="font-latoLight text-ttWhite p-2 bb-8 md:p-8 text-justify">
                            How to Play with MetaMask
                        </p>
                        <YoutubeEmbed embedId="WQFmSw85YRs" className="md:w-400" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowToPlayVideos
