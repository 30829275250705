import React from "react"
import stepOne from "../assets/images/step1mobile.svg"
import stepTwo from "../assets/images/step2mobile.svg"
import stepThree from "../assets/images/step3mobile.svg"
import stepFour from "../assets/images/step4mobile.svg"
import stepFive from "../assets/images/step5mobile.svg"
import stepOneDesktop from "../assets/images/step1desktop.svg"
import stepTwoDesktop from "../assets/images/step2desktop.svg"
import stepThreeDesktop from "../assets/images/step3desktop.svg"
import stepFourDesktop from "../assets/images/step4desktop.svg"
import stepFiveDesktop from "../assets/images/step5desktop.svg"
import arrow from "../assets/images/arrow1.svg"
import arrownum from "../assets/images/arrowwithnumbers.svg"

const HowItWorks = () => {
    return (
        <section>
            {/* How it Works Section */}
            <div className=" flex flex-col items-center mx-auto mb-0 md:mb-8 h-fit">
                <div>
                    <h1 className="mt-8 font-ubuntuBold text-2xl md:mb-8">How It Works</h1>
                </div>
                {/* Mobile */}
                <div className="container flex flex-col items-center w-64 mx-auto py-8 mb-4 mt-8 h-fit md:hidden">
                    <div className="mb-8">
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            1
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl">
                            Chainlink Keepers Performs Upkeep (initiates Tiditada Contract Execution
                            after all Raffle Conditions are met)
                        </p>
                        <div className="mt-4 mx-auto">
                            <img src={stepOne} width={280} height={0} alt="img" />
                        </div>
                    </div>
                    <div>
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            2
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl mb-4">
                            Tiditada application sends request for a random number to Chainlink VFR
                        </p>
                        <div className="mx-auto mb-8">
                            <img
                                src={stepTwo}
                                width={160}
                                height={0}
                                alt="img"
                                className="mx-auto"
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            3
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl mb-4">
                            VRF generates a Random Number (RN) and a cryptographic proof of how the
                            number was generated
                        </p>
                        <div className="mx-auto mb-8">
                            <img
                                src={stepThree}
                                width={160}
                                height={0}
                                alt="img"
                                className="mx-auto"
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            4
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl mb-4">
                            The generated Random Number is published and verified on-chain. (This is
                            to ensure that the number cannot be tampoered with by oracle operators,
                            miners, tiditada developers or any single entity
                        </p>
                        <div className="mx-auto mb-8">
                            <img
                                src={stepFour}
                                width={160}
                                height={0}
                                alt="img "
                                className="mx-auto"
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            5
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl mb-4">
                            Tiditada receives the RN and uses it to select a winner from the list of
                            players using modular arithmetic
                        </p>
                        <div className="mx-auto mb-8">
                            <img
                                src={stepFive}
                                width={160}
                                height={0}
                                alt="img"
                                className="mx-auto"
                            />
                        </div>
                    </div>
                </div>
                {/* Desktop */}
                <div className="hidden container md:flex md:flex-col items-center">
                    <div className="flex flex-col items-center">
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl w-64">
                            1
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl w-64">
                            Chainlink Keepers Performs Upkeep (initiates Tiditada Contract Execution
                            after all Raffle Conditions are met)
                        </p>
                        <div className="mx-auto mb-8">
                            <img src={stepOneDesktop} width={640} height={0} alt="img" />
                        </div>
                    </div>
                    <div className="hidden md:flex md:flex-row md:items-center md:space-x-8">
                        <div className="flex flex-col items-center justify-center">
                            <div>
                                <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl w-40">
                                    2
                                </p>
                                <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl w-40">
                                    Tiditada application sends request for a random number to
                                    Chainlink VFR
                                </p>
                            </div>
                            <div className=" mx-auto mt-4">
                                <img src={stepTwoDesktop} width={200} height={0} alt="img" />
                            </div>
                        </div>
                        <div>
                            <img src={arrow} width={1800} height={0} alt="img" />
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <div>
                                <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl w-40">
                                    3
                                </p>
                                <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl w-40">
                                    VRF generates a Random Number (RN) and a cryptographic proof of
                                    how the number was generated
                                </p>
                            </div>
                            <div className=" mx-auto mt-4">
                                <img src={stepThreeDesktop} width={200} height={0} alt="img" />
                            </div>
                        </div>
                        <div>
                            <img src={arrownum} width={1800} height={1800} alt="img" />
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <div>
                                <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl w-40">
                                    4
                                </p>
                                <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl w-40">
                                    The generated Random Number is published and verified on-chain.
                                    (This is to ensure that the number cannot be tampoered with by
                                    oracle operators, miners, tiditada developers or any single
                                    entity
                                </p>
                            </div>
                            <div className=" mx-auto mt-4">
                                <img src={stepFourDesktop} width={164} height={0} alt="img" />
                            </div>
                        </div>
                        <div>
                            <img src={arrow} width={1800} height={800} alt="img" />
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <div>
                                <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl w-40">
                                    5
                                </p>
                                <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttPink inline-block rounded-bl-xl rounded-br-xl w-40">
                                    Tiditada receives the RN and uses it to select a winner from the
                                    list of players using modular arithmetic
                                </p>
                            </div>
                            <div className=" mx-auto mt-4">
                                <img src={stepFiveDesktop} width={200} height={0} alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWorks
