import React from "react"
import YoutubeEmbed from "../components/YoutubeEmbed"

const HowToPlay = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h5 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        How To Play
                    </h5>

                    <p className="font-latoBold text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        How to Play with Trust Wallet
                    </p>
                    <YoutubeEmbed embedId="DeBI2DgqDW0" />

                    <p className="font-latoBold text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        How to Play with MetaMask
                    </p>
                    <YoutubeEmbed embedId="WQFmSw85YRs" />
                </div>
            </div>
        </section>
    )
}

export default HowToPlay
