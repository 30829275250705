import Hero from "../components/Hero"
import TTCategories from "../components/TTCategories"
import HowItWorks from "../components/HowItWorks"
import HowToPlay from "../components/HowToPlay"
import RecentWinnersList from "../components/RecentWinnersList"
import HowToPlayVideos from "../components/HowToPlayVideos"
import Timer from "../components/Timer"

const Home = () => {
    return (
        <div>
            {/* <Head>
                <title>TidiTada Raffle</title>
                <meta
                    name="World's First 100% Transparent Decentralized Automated Raffle"
                    content="created by Vanga Labs"
                />
                <link rel="icon" href="/favicon.svg" />
            </Head> */}

            <Timer />
            <Hero />
            <TTCategories />
            <RecentWinnersList />
            <HowToPlayVideos />
            <HowToPlay />
            <HowItWorks />
        </div>
    )
}

export default Home
