import React from "react"
import PointFiveMaticCategory from "./PointFiveMaticCategory"
import TwoMatic from "./TwoMatic"
import ZeroPointZeroOneEth from "./ZeroPointZeroOneEth"
import ZeroPointOneBnb from "./ZeroPointOneBnb"

const TTCategories = () => {
    return (
        <section>
            {/* Categories Section */}
            <div className=" flex flex-col items-center my-8 mx-auto bg-ttLightBg md:my-8 ">
                <div>
                    <h1 className=" font-ubuntuBold text-2xl my-8 md:my-8">Raffle Categories</h1>
                </div>
                <div className="flex flex-col items-center lg:flex lg:flex-row lg:items-center lg:justify-between md:pb-12 ">
                    <PointFiveMaticCategory />
                    <TwoMatic />
                    <ZeroPointZeroOneEth />
                    <ZeroPointOneBnb />
                </div>
            </div>
        </section>
    )
}

export default TTCategories
