import React from "react"
import confirmRecoveryPhrase from "../assets/images/confirmRecoveryPhrase.svg"
import congratulations from "../assets/images/congratulations.svg"
import createPassword from "../assets/images/createPassword.svg"
import ethOne from "../assets/images/eth-1.svg"
import ethTwo from "../assets/images/eth-2.svg"
import helpUsImprove from "../assets/images/helpUsImprove.svg"
import metamask from "../assets/images/metamask.svg"
import network from "../assets/images/network.svg"
import saveOrImport from "../assets/images/saveOrImport.svg"
import secretRecoveryPhrase from "../assets/images/secretRecoveryPhrase.svg"
import secureWallet from "../assets/images/secureWallet.svg"
import addNetworks from "../assets/images/addNetworks.svg"
import welcome from "../assets/images/welcome.svg"

const AddPolygonToMetamask = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h6 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        How to add Polygon (MATIC) to MetaMask
                    </h6>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        <p className="font-latoBold">TL;DR</p>
                        MetaMask is a crypto wallet app and browser extension that by default is
                        connected to the Ethereum mainnet. You can find the extension on the
                        official{" "}
                        <a className="font-lato text-ttHero underline" href="https://metamask.io/">
                            MetaMask
                        </a>{" "}
                        website, which will direct you to the correct place to download it. To
                        connect to other networks like Polygon, you'll need to add some basic
                        blockchain information to MetaMask. This includes a custom RPC URL (a URL
                        that connects the app to polygon blockchain), chain ID, and network name.
                    </p>

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        <p className="font-latoBold">Introduction</p>
                        Interacting with the Polygon blockchain requires a compatible crypto wallet
                        such as MetaMask. However, MetaMask doesn’t have Polygon added as a network
                        by default. Setting up your browser wallet to connect to Polygon is simple
                        and can be done in just a few minutes.
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        <p className="font-latoBold">Installing and setting up MetaMask</p>
                        1. MetaMask is available to download and install on Chrome, iOS, or Android
                        through the MetaMask website. Always check if you are using the official
                        website to make sure you’re downloading the real MetaMask extension.
                    </p>
                    <img
                        src={metamask}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={metamask}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        2. After downloading and installing the extension, you’ll be greeted with
                        the MetaMask welcome page. Click [Get Started] to begin.
                    </p>
                    <img
                        src={welcome}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={welcome}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        3. If you’re creating a new wallet, click the [Create a Wallet] button. You
                        can also import an old wallet using its seed phrase with the [Import wallet]
                        option.
                    </p>
                    <img
                        src={saveOrImport}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={saveOrImport}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        4. MetaMask will ask you if you’d like to share anonymous usage data with
                        them to help improve the extension. Accepting or refusing this will not
                        affect your MetaMask usage.
                    </p>
                    <img
                        src={helpUsImprove}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={helpUsImprove}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        5. Choose a secure password to log in to your wallet from your browser. Note
                        that this is not your seed phrase. The password is a security measure to
                        prevent anyone using your device from accessing your wallet. If you forget
                        your password, you can always access your crypto with your seed phrase.
                    </p>
                    <img
                        src={createPassword}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={createPassword}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        6. After choosing your password, MetaMask will present you with information
                        regarding your wallet’s seed phrase. Make sure to read through it if you
                        aren’t familiar with how a crypto wallet works.
                    </p>
                    <img
                        src={secureWallet}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={secureWallet}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        7. You’ll now be presented with your seed phrase. Click the lock to view the
                        words and take note of them in the correct order. Keep the phrase in a
                        secure place (preferably offline) and never share it with anyone. This
                        string of numbers is the final backup of your wallet and its contents. Click
                        [Next] to continue.
                    </p>
                    <img
                        src={secretRecoveryPhrase}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={secretRecoveryPhrase}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        8. You’ll now have to repeat your seed phrase by selecting the words at the
                        bottom of the screen in the right order. Click [Confirm] once this is
                        complete.
                    </p>
                    <img
                        src={confirmRecoveryPhrase}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={confirmRecoveryPhrase}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        9. Your MetaMask wallet will now be set up ready to use. Click [All Done] to
                        view your wallet.
                    </p>

                    <img
                        src={congratulations}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={congratulations}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        10. To make MetaMask easy to access, you can pin it to your Chrome browser
                        by clicking the puzzle icon and pinning MetaMask to the toolbar. After the
                        initial setup, MetaMask will only be connected to Ethereum. Next, we will
                        see how to connect Metamask to Polygon.
                    </p>
                    <img
                        src={ethOne}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={ethOne}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        <p className="font-latoBold">Configuring the wallet</p>
                        1. Adding Polygon support to your wallet involves adding some network
                        details to the extension. First, open MetaMask and click the network
                        dropdown menu.
                    </p>
                    <img
                        src={ethTwo}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={ethTwo}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        2. Now, click [Add Network] on the pop-up.
                    </p>
                    <img
                        src={network}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={network}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        3. You’ll need to add the following details on the [Add a network] page that
                        will open. Click [Save] when you’re finished.
                    </p>
                    <div className="md:ml-64">
                        <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-left">
                            <span className="font-latoBold mr-4">Network Name:</span> Polygon
                        </p>
                        <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-left">
                            <span className="font-latoBold mr-4">New RPC URL:</span> Choose any of
                            the following: <br />
                            <a href=" https://polygon-rpc.com" className="underline text-ttHero">
                                {" "}
                                https://polygon-rpc.com
                            </a>{" "}
                            <br />
                            <a
                                href=" https://rpc-mainnet.maticvigil.com "
                                className="underline text-ttHero"
                            >
                                {" "}
                                https://rpc-mainnet.maticvigil.com
                            </a>{" "}
                        </p>
                        <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-left">
                            <span className="font-latoBold mr-4">Chain ID:</span> 137
                        </p>
                        <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-left">
                            <span className="font-latoBold mr-4">Currency Symbol:</span> MATIC
                        </p>
                        <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-left">
                            <span className="font-latoBold mr-4">Block Explorer URL:</span>{" "}
                            <a
                                href=" https://www.polygonscan.com/"
                                className="underline text-ttHero"
                            >
                                {" "}
                                https://polygonscan.com/
                            </a>{" "}
                        </p>
                    </div>
                    <img
                        src={addNetworks}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={addNetworks}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        4. You’ll now successfully be connected to the Polygon network.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default AddPolygonToMetamask
