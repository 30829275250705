import React from "react"
import { Link } from "react-router-dom"

const ErrorPage = () => {
    return (
        <section>
            {/* Error Page */}
            <div className=" container flex flex-col items-center mx-auto mb-0 md:mb-8 h-fit">
                <div>
                    <h1 className="mt-8 font-ubuntuBold text-4xl md:text-8xl text-ttPrimary md:mb-8 md:mt-12">
                        Oops!
                    </h1>
                </div>
                <div>
                    <p1 className="mt-8 font-latoRegular text-2xl md:text-3xl text-ttPrimary md:mb-8">
                        404 - PAGE NOT FOUND
                    </p1>
                </div>
                <div className="w-96 mb-12">
                    <p1 className="mt-8 font-latoRegular text-center  text-ttGrey md:mb-8 md:pt-8">
                        The page you're looking for might have been removed, had its name changed or
                        is temporarily unavailable
                    </p1>
                </div>
                <div className="mb-32">
                    <Link to="/">
                        <a className="text-ttWhite bg-ttPrimary p-4 rounded-xl hover:bg-ttPrimaryLight">
                            Back to Home Page
                        </a>
                    </Link>
                </div>
            </div>
        </section>
    )
}

export default ErrorPage
