import React from "react"
import { Link } from "react-router-dom"

import play from "../assets/images/play.svg"
import notification from "../assets/images/notification.svg"
import confirmPlay from "../assets/images/confirmPlay.svg"
import connectToSite from "../assets/images/connectToSite.svg"
import walletConnectMetamask from "../assets/images/walletConnectMetamask.svg"
import walletConnectPage from "../assets/images/walletConnectPage.svg"
import connectPage from "../assets/images/connectPage.svg"
import connectPage2 from "../assets/images/connectPage2.svg"

const PlayMetamask = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h6 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        How to play via MetaMask
                    </h6>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        1. Navigate to Raffle Page and click on the "Connect Wallet". If you do not
                        have a wallet. Click{" "}
                        <Link to="/add-polygon-to-metamask" className=" text-ttHero">
                            here
                        </Link>{" "}
                        to set up a MetaMask wallet.
                    </p>
                    <img
                        src={connectPage}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={connectPage}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <img
                        src={connectPage2}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={connectPage2}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        2. If you are playing via mobile, select "WalletConnect" from the list of
                        wallets. Desktop users should select "MetaMask" instead.
                    </p>
                    <img
                        src={walletConnectPage}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={walletConnectPage}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        3. Select "MetaMask" from list of wallets
                    </p>
                    <img
                        src={walletConnectMetamask}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={walletConnectMetamask}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        4. Click on the "Connect" Button
                    </p>
                    <img
                        src={connectToSite}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={connectToSite}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        5. You will be redirected your browser. Click "Play" afterwards
                    </p>
                    <img
                        src={play}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={play}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        6. Click "Confirm" to approve Raffle entry
                    </p>
                    <img
                        src={confirmPlay}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={confirmPlay}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        7. You'll get a notification of transaction submission and confirmation
                    </p>
                    <img
                        src={notification}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={notification}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        And that's it. you have successfully entered the Tiditada Raffle which makes
                        you a potential winner. <br />
                        Goodluck!
                    </p>
                </div>
            </div>
        </section>
    )
}

export default PlayMetamask
