import React from "react"

const RaffleConditions = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h5 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        Raffle Conditions
                    </h5>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        For the Raffle to execute, the following conditons must be met.
                    </p>
                    <ul className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        <li>
                            1. The time interval (7 days) must have passed after the last Raffle
                            Draw.
                        </li>
                        <li> 2. The number of players must be greater than nine (9).</li>
                    </ul>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        If these conditions are not met at raffle execution time, the Raffle remains
                        OPEN and only executes when all conditons are met.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default RaffleConditions
