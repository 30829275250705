import { useState } from "react"

import { Link, BrowserRouter as Router } from "react-router-dom"
import { Transition } from "@headlessui/react"
import logo from "../assets/images/logo.svg"

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div>
            <nav className="relative container mx-auto p-2 px-4 md:p-6">
                <div className="w-full">
                    <div className="flex items-center h-20 w-full">
                        <div className="flex items-center   justify-between w-full">
                            <div className="flex justify-center items-center flex-shrink-0 ">
                                <div className="pl-0 pt-2 cursor-pointer md:pl-0">
                                    <Link to="/">
                                        <img src={logo} width={196} height={64} alt="img" />
                                    </Link>
                                </div>
                            </div>
                            <div className="hidden md:block">
                                <div className="ml-10 flex items-baseline space-x-4">
                                    <Link
                                        to="/matic"
                                        className="  cursor-pointer text-ttPrimary font-lstoRegular   hover:text-ttHero"
                                    >
                                        <a className="mx-4 hover:text-ttHero">Play with MATIC</a>
                                    </Link>
                                    <Link
                                        to="/bnb"
                                        className="cursor-pointer text-ttPrimary font-lstoRegular   hover:text-ttHero"
                                    >
                                        <a className="mx-4 hover:text-ttHero">Play with BNB</a>
                                    </Link>
                                    <Link
                                        to="/developers"
                                        className="cursor-pointer text-ttPrimary font-lstoRegular   hover:text-ttHero"
                                    >
                                        <a className="mr-4 hover:text-ttHero">Developers</a>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className=" pl-2 flex md:hidden  ">
                            <button
                                onClick={() => setIsOpen(!isOpen)}
                                type="button"
                                className="bg-ttPrimary inline-flex items-center justify-center p-2 rounded-md text-ttWhite  hover:bg-ttPrimaryLight focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
                                aria-controls="mobile-menu"
                                aria-expanded="false"
                            >
                                <span className="sr-only">Open main menu</span>
                                {!isOpen ? (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 6h16M4 12h16M4 18h16"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        className="block h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M6 18L18 6M6 6l12 12"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <Transition
                    show={isOpen}
                    enter="transition ease-out duration-100 transform"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-75 transform"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    {(ref) => (
                        <div className="md:hidden " id="mobile-menu ">
                            <div
                                ref={ref}
                                className="bg-ttPrimary px-2 pt-2 pb-3 space-y-4 rounded-xl sm:px-3 flex flex-col items-center"
                            >
                                <Link
                                    to="/matic"
                                    className="cursor-pointer text-ttWhite hover:text-ttHero block py-2 font-latoRegular"
                                >
                                    <a className="text-ttWhite font-latoRegular">Play with Matic</a>
                                </Link>
                                <Link
                                    to="/bnb"
                                    className="cursor-pointer text-ttWhite hover:text-ttHero block py-2   font-latoRegular"
                                >
                                    <a className="text-ttWhite font-latoRegular">Play with BNB</a>
                                </Link>

                                <Link
                                    to="/developers"
                                    className="cursor-pointer text-ttWhite hover:text-ttHero block py-2   font-latoRegular"
                                >
                                    <a className="text-ttWhite font-latoRegular">Developers</a>
                                </Link>
                            </div>
                        </div>
                    )}
                </Transition>
            </nav>
        </div>
    )
}

export default Header
