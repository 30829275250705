import React from "react"
import { Link } from "react-router-dom"

import copyMatic from "../assets/images/copyMatic.svg"
import receiveMatic from "../assets/images/receiveMatic.svg"
import selectMatic from "../assets/images/selectMatic.svg"
import maticHome from "../assets/images/maticHome.svg"
import binanceWithdraw from "../assets/images/binanceWithdraw.svg"
import binanceDashboard from "../assets/images/binanceDashboard.svg"
import enterAmount from "../assets/images/enterAmount.svg"
import withdrawSteps from "../assets/images/withdrawSteps.svg"

const BinanceToMetamask = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h6 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        How to Transfer Polygon (MATIC) From Binance to MetaMask
                    </h6>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Read to learn exactly how you can transfer your crypto from Binance to your
                        MetaMask in just a few steps. This procedure works for other centralized
                        exchanges like Coinbase, KuCoin, Gateio, e.t.c
                    </p>

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        <p className="font-latoBold">How to Withdraw Funds From Binance</p>
                        Before you can get started withdrawing your MATIC from Binance, you will
                        first need to set up MetaMask (if you haven’t already). You can find
                        instructions oh how to set up MetaMask{" "}
                        <Link to="/add-polygon-to-metamask" className=" text-ttHero">
                            here
                        </Link>
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        You can download the MetaMask app on your Android or iOS smartphone from{" "}
                        <a href="https://metamask.io" className="text-ttHero">
                            MetaMask
                        </a>{" "}
                        and complete the simple setup process. You can also get the MetaMask browser
                        extension for your mobile browser
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Next, go to Binance.com, log into your account, and proceed to complete your
                        two-factor authentication (2FA) verification. Alternatively, you can also
                        access your Binance account using the Binance mobile app on your phone. If
                        you do not already have a Binance account, you can learn how to create one{" "}
                        <a
                            href="https://www.binance.com/en/support/faq/how-to-register-on-binance-app-360042718372"
                            className="text-ttHero"
                        >
                            here
                        </a>{" "}
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Now, it’s simple to check your Binance account’s wallets to see the digital
                        currencies you hold.
                    </p>
                    <img
                        src={binanceDashboard}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={binanceDashboard}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />

                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Now it is time to get your MATIC wallet address for your MetaMask to do
                        that, open you MetaMask app and do the following.
                    </p>
                    <img
                        src={maticHome}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={maticHome}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        2. Select MATIC by clicking on it
                    </p>
                    <img
                        src={selectMatic}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={selectMatic}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        3. Click on the [Receive] button and copy and paste the MATIC wallet
                        address. Make sure to carefully check the network you selected. MATIC is
                        available on the Polygon Mainnet (MATIC).
                    </p>
                    <img
                        src={receiveMatic}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={receiveMatic}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        4. Click on the [Copy] button to copy your MATIC wallet address.
                    </p>
                    <img
                        src={copyMatic}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={copyMatic}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        5. Head over to your Binance account and click on [withdraw] on your
                        dashboard
                    </p>
                    <img
                        src={binanceWithdraw}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={binanceWithdraw}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        6. Select MATIC under [Coin] dropdown
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        7. Paste the MATIC wallet address copied from MetaMask under the [Address]
                        section.
                    </p>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        8. Next, choose the Matic in the [Network] drop-down
                    </p>
                    <img
                        src={withdrawSteps}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={withdrawSteps}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        9. Enter withdrawal amount under [Amount] section and click on withdraw
                    </p>
                    <img
                        src={enterAmount}
                        width={280}
                        height={0}
                        alt="img"
                        className="md:hidden mx-auto my-4"
                    />
                    <img
                        src={enterAmount}
                        width={512}
                        height={0}
                        alt="img"
                        className="hidden md:block md:mx-auto md:my-4"
                    />
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        And that’s it! That’s how easy it is to transfer MATIC from Binance to
                        MetaMask.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default BinanceToMetamask
