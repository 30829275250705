import React from "react"
import MaticCategories from "../components/contractCategories/matic/MaticCategories"

import HowToPlay from "../components/HowToPlay"

const Matic = () => {
    return (
        <div>
            <MaticCategories />
            <HowToPlay />
        </div>
    )
}

export default Matic
