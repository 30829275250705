import React from "react"
import { Link } from "react-router-dom"

import logo from "../assets/images/white-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Disclaimer = () => {
    return (
        <section>
            {/* Footer Section */}
            <div className="  container mx-auto px-4 mt-2 mb-4 md:px-8 md:mt-12 md:mb-12">
                <div
                    className="border-ttGrey border-x border-y rounded-4xl rounded-2xl bg-ttPink bg-opacity-25 drop-shadow-2xl

"
                >
                    <h5 className="text-ttPrimary font-ubuntuBold text-center mt-4 md:text-2xl md:mt-8">
                        Tiditada Protocol Disclaimer
                    </h5>
                    <p className="font-latoRegular text-ttPrimar px-8 pt-8 md:p-8 text-justify">
                        Tiditada is a decentralised transparent provably-fair provably-verifiable
                        raffle protocol that people interact with to win ERC-20, MATIC or BEP-20
                        native tokens after joining with an Entrance Fee. There are three versions
                        of the Tiditada protocol (ERC-20, MATIC, and BEP-20) each of which is made
                        up of free, public, open-source or source-available software including a set
                        of smart contracts that are deployed on the various Blockchains. Your use of
                        the Tiditada protocol involves various risks, including, but not limited to,
                        losses while digital assets are being supplied to the Tiditada protocol and
                        losses due to the fluctuation of prices of tokens. Before using the Tiditada
                        protocol, you should review the relevant documentation to make sure you
                        understand how the Tiditada protocol works. Additionally, you can access the
                        Tiditada protocol through dozens of web or mobile interfaces. You are
                        responsible for doing your own diligence on those interfaces to understand
                        the fees and risks they present.
                    </p>
                    <p className="font-latoBold text-ttPrimary p-8 bb-8 md:p-8 text-justify">
                        THE TIDITADA PROTOCOL IS PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUT
                        WARRANTIES OF ANY KIND. Although Vanga DAO which is a Decentralised
                        Autonomous Organisation developed much of the initial code for the Tiditada
                        protocol, it does not provide, own, or control the Tiditada protocol, which
                        is run by smart contracts deployed on the Ethereum, Polygon and Binance
                        blockchains. Upgrades and modifications to the protocol are managed in a
                        community-driven way by holders of the VANGA DAO governance token. No
                        developer or entity involved in creating the Tiditada protocol will be
                        liable for any claims or damages whatsoever associated with your use,
                        inability to use, or your interaction with other users of, the Tiditada
                        protocol, including any direct, indirect, incidental, special, exemplary,
                        punitive or consequential damages, or loss of profits, cryptocurrencies,
                        tokens, or anything else of value.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Disclaimer
