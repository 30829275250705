import React from "react"
import { Link } from "react-router-dom"

const HowToPlay = () => {
    return (
        <section>
            {/* How to Play Section */}
            <div className=" flex flex-col items-center mx-auto h-fit mt-4 md:mt-12">
                <div>
                    <h1 className="mt-8 font-ubuntuBold text-2xl ">Instructions on How To Play</h1>
                </div>
                <div className="container flex flex-col items-center w-64 mx-auto py-8 mb-4 h-fit md:w-auto md:flex md:flex-row md:items-center md:justify-between md:space-x-16">
                    <div className="border-solid rounded-xl border-ttGrey">
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            1
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttWhite inline-block rounded-bl-xl rounded-br-xl mb-4">
                            Select Raffle Category from the Raffle Categories section above
                        </p>
                    </div>
                    <div>
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            2
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttWhite inline-block rounded-bl-xl rounded-br-xl mb-4">
                            Ensure that you're on the appropriate network and Connect Wallet
                        </p>
                    </div>
                    <div>
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            3
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttWhite inline-block rounded-bl-xl rounded-br-xl mb-4">
                            Fund the Smart Contract. The Winner will be published once the Raffle
                            takes place. Raffle data will be publicly available on chain explorer
                            for confirmation
                        </p>
                        <Link
                            to="/play-with-metamask"
                            className="text-center bg-ttPrimary text-ttWhite hover:bg-ttPrimaryLight p-2 rounded-xl ml-20 md:ml-16"
                        >
                            Learn More
                        </Link>
                    </div>
                    <div>
                        <p className="text-4xl mb-4 font-ubuntuBold text-ttWhite p-4 bg-ttPrimary rounded-lg mt-8 md:mt-0">
                            OR
                        </p>
                    </div>
                    <div>
                        <p className="text-center text-ttWhite font-latoBold p-2 bg-ttPrimary block rounded-tl-xl rounded-tr-xl">
                            Transfer
                        </p>
                        <p className="text-center text-ttPrimary font-latoRegular text-xs p-4 bg-ttWhite inline-block rounded-bl-xl rounded-br-xl mb-4">
                            Navigate to Raffle Page and send Raffle Entrace Fee to Raffle Contract
                            Address (Ensure that Entrance Fee is sent via appropriate network listed
                            on the Raffle Page).
                        </p>

                        <Link
                            to="/play-with-binance"
                            className="text-center bg-ttPrimary text-ttWhite hover:bg-ttPrimaryLight p-2 rounded-xl ml-20"
                        >
                            Learn More
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowToPlay
