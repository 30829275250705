import { ethers } from "ethers"
import agV3I from "../constants/aggregatorV3Interface.json"

export async function getMaticPriceInUSD() {
    const provider = new ethers.providers.JsonRpcProvider(
        "https://polygon-mainnet.g.alchemy.com/v2/VXMPPSJdUebud5dmhgJTyBgCRujYdSUn"
    )

    // This constant describes the ABI interface of the contract, which will provide the price of ETH
    // It looks like a lot, and it is, but this information is generated when we compile the contract
    // We need to let ethers know how to interact with this contract.
    const aggregatorV3InterfaceABI = agV3I

    // The address of the contract which will provide the price of ETH
    const addr = "0xab594600376ec9fd91f8e885dadf0ce036862de0"
    // We create an instance of the contract which we can interact with
    const priceFeed = new ethers.Contract(addr, aggregatorV3InterfaceABI, provider)
    // We get the data from the last round of the contract
    let roundData = await priceFeed.latestRoundData()
    // Determine how many decimals the price feed has (10**decimals)
    let decimals = await priceFeed.decimals()
    // We convert the price to a number and return it
    return Number((roundData.answer.toString() / Math.pow(10, decimals)).toFixed(2))
}
