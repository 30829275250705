import React from "react"
import { useEffect, useState } from "react"
import moment from "moment"
import maticImage from "../assets/images/matic-2.svg"
import {
    getNumPlayersFromCall,
    getIntervalFromCall,
    getLastTimeStampFromCall,
} from "../utils/05-matic-interact"

import { getMaticPriceInUSD } from "../utils/getMaticPrice"
const PointFiveMatic = () => {
    const image = maticImage
    const network = "MATIC"
    const entryPrice = 0.5
    const token = "MATIC"
    const comingSoon = false
    const contractUrl = "https://05-matic.tiditada.com"

    const [numPlayers, setNumPlayers] = useState(0)
    const [timeStamp, setTimeStamp] = useState(0)
    const [maticPriceInUSD, setMaticPriceInUSD] = useState(0)

    async function fetchNumPlayers() {
        const players = await getNumPlayersFromCall()
        setNumPlayers(players)
    }

    async function fetchMaticPrice() {
        const maticPrice = await getMaticPriceInUSD()
        setMaticPriceInUSD(maticPrice)
    }
    async function fetchInterval() {
        const int = await getIntervalFromCall()
        setInterval(int)
    }

    async function fetchTimeStamp() {
        const ts = await getLastTimeStampFromCall()
        setTimeStamp(ts)
    }

    useEffect(() => {
        fetchNumPlayers()
        fetchInterval()
        fetchTimeStamp()
        fetchMaticPrice()
    }, [])

    return (
        <div className="relative my-0">
            <img src={image} width={256} height={760} alt="img" />
            <div className="absolute bottom-4 left-8 mx-auto">
                <div
                    className="border-solid border-ttPrimaryLight rounded-lg h-fit bg-ttCatBg p-8 w-48 shadow-inner

"
                >
                    <h1 className="text-ttPrimary font-latoBold text-xs text-center mb">
                        Prize Money
                    </h1>
                    <h1 className="text-ttPrimary font-latoBold text-xl text-center">{`${
                        entryPrice * numPlayers
                    } ${token}`}</h1>
                    <h1 className="text-ttPrimary font-latoBold text-xs text-center mb-4">{`$(${(
                        entryPrice *
                        numPlayers *
                        maticPriceInUSD
                    ).toFixed(2)})`}</h1>

                    <p className="text-center text-ttGrey text-xs mb-4">
                        Winner Takes: <br />
                        {`${(entryPrice * numPlayers * 0.85).toFixed(2)} ${token}`} <br />
                        {`($${(entryPrice * numPlayers * 0.85 * maticPriceInUSD).toFixed(2)})`}{" "}
                    </p>
                    <p className="text-ttRed text-center text-xs font-latoBold">Entry Price</p>
                    <p className="text-ttRed text-center text-2xl font-latoBold">{`${entryPrice} ${token}`}</p>
                    <p className="text-ttGrey text-center text-xs font-latoBold mb-4">{`$(${(
                        entryPrice * maticPriceInUSD
                    ).toFixed(2)}) USD`}</p>

                    <p className="text-ttPrimary text-center text-xs">Number of Players:</p>
                    <p className="text-ttPrimary text-center text-xl font-latoBold mb-4">
                        <div>
                            {timeStamp < 604801 ? (
                                <div className="skeleton skeleton-loading skeleton-numplayers mb-4 mx-auto mt-2"></div>
                            ) : (
                                <div>{`${numPlayers}`}</div>
                            )}
                        </div>
                    </p>

                    <p className="text-center text-ttWhite bg-ttPrimary pt-2 rounded-tl-xl rounded-tr-xl">
                        Network
                    </p>
                    <p className="text-center text-ttRed font-latoBold bg-white pb-2 rounded-bl-xl rounded-br-xl mb-4">
                        {network}
                    </p>

                    <div className="text-ttGrey text-center text-xs font-latoBold">
                        Raffle Ends: <br />{" "}
                        {/* {moment(Number(timeStamp * 1000 + 604800 * 1000)).format(
                            "dddd, MMMM Do YYYY, h:mm:ss a"
                        )} */}
                        {timeStamp < 604801 ? (
                            <div className="flex flex-col items-center">
                                <div className="skeleton skeleton-loading skeleton-enddate"></div>
                                <div className="skeleton skeleton-loading skeleton-enddate"></div>
                            </div>
                        ) : (
                            moment(Number(timeStamp * 1000 + 604800 * 1000)).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                            )
                        )}
                    </div>
                </div>

                <div className="mt-4">
                    {comingSoon ? (
                        <button
                            className="text-sm text-ttWhite bg-ttDisabled p-4 rounded-xl px-12 mx-auto "
                            disabled
                        >
                            Coming Soon...
                        </button>
                    ) : (
                        <a href={contractUrl}>
                            <button className="animate-pulse text-ttWhite bg-ttPrimary p-4 rounded-xl px-16 hover:bg-ttPrimaryLight ">
                                Play Now
                            </button>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PointFiveMatic
