import React from "react"
import BNBCategories from "../components/contractCategories/bnb/BNBCategories"

import HowToPlay from "../components/HowToPlay"

const BNB = () => {
    return (
        <div>
            <BNBCategories />
            <HowToPlay />
        </div>
    )
}

export default BNB
